<template>
  <div>
    <vs-tabs class="invoice-list">
      <vs-tab label="Clinic Details">
        <clinicEditDetail />
      </vs-tab>
      <vs-tab label="Users & Accounts">
        <vx-card style="padding-left: 10px" id="editPage">
          <clinic-users />
        </vx-card>
      </vs-tab>
      <!-- <vs-tab label="Treatments">
        <treatment-list :inTabEdit="true" />
      </vs-tab> -->
      <vs-tab label="Invoices">
        <invoice-list :inTabEdit="true" />
      </vs-tab>
      <vs-tab label="Orders">
        <clinic-orderList viewDetailRoute="OrderDetail" />
      </vs-tab>
      <vs-tab label="Compliance">
        <monthlyComplianceList/>
      </vs-tab>
      <vs-tab label="Settings">
        <clinicSettingsEditDetail />
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import clinicViewEditDetail from "../../components/clinics/viewEditDetail.vue";
import doctorTable from "../../components/doctors/doctorTable.vue";
import clinicEditDetail from "../../components/clinics/viewEditClinicDetail.vue";
import nurseTable from "../../components/nurses/nurseTable.vue";
import treatmentList from "../../clinic/history/TreatmentHistory.vue";
import invoiceList from "../../components/invoice/List.vue";
import ClinicOrderList from "../../components/order-center/order/ClinicOrderList.vue";
import monthlyComplianceList from "../../components/clinics/complianceList.vue";
import clinicSettingsEditDetail from "../../components/clinics/ViewEditClinicSettings.vue";
import clinicUsers from "../../components/clinics/ClinicUsers.vue";

export default {
  components: {
    clinicViewEditDetail,
    clinicEditDetail,
    nurseTable,
    doctorTable,
    treatmentList,
    invoiceList,
    ClinicOrderList,
    clinicSettingsEditDetail,
    monthlyComplianceList,
    clinicUsers,
  },
  data: () => ({}),
  methods: {
    ...mapActions("general", ["fetchCardDetailsList"]),
    async created() {
      await this.fetchCardDetailsList();
    },
  },
};
</script>


<style>
.invoice-list .con-ul-tabs {
  height: 40px;
  overflow-x: auto;
  overflow-y: hidden;
}

.invoice-list .con-ul-tabs::-webkit-scrollbar {
  display: none;
}
</style>
